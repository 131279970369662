import React from 'react'
import { FormattedMessage } from 'react-intl'

const PageTitle = ({ subTitle, mainTitle, classes }) => (
    <div>
        <em><FormattedMessage id={subTitle}/></em>
        <h1 className={classes}>
            <span><FormattedMessage id={mainTitle}/></span>
        </h1>
    </div>
);

export default PageTitle



