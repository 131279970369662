import React from 'react'
import Header from '../components/global/header/header'
import Hero from '../components/heros/alternate'
import CallToAction from '../components/global/cta'
import Footer from '../components/global/footer/footer'
import PageTransition from 'gatsby-plugin-page-transitions'
import network from '../utils/network';
import { useStaticQuery, graphql } from "gatsby"
import SingleCaseStudy from './single-casestudy'
import { withIntl } from '../i18n'
import globalStore from '../utils/globalStore'
import _ from 'lodash';


// Images
import ctaImage from '../images/7Z8A0083_Output.jpg'
import heroImage from '../images/case-studies-banner.jpg'

import CaseStudiesListing from "../components/case-studies/listing";

class CaseStudies extends React.Component {
  constructor(props) {

    super(props);
    this.state = {
      contactOpen: false,
      applyOpen:false,
      caseStudies: []
    };
  }

  toggleContact = () => {
    this.setState({
      contactOpen: !this.state.contactOpen
    });
    document.body.classList.toggle('noscroll');
  }

  componentWillMount() {
    let {state} = this;

    // check URL for article slug and show single article view
    const {pathname} = this.props.location;

	const {data} = this.props

	const caseStudies = data.allMarkdownRemark.nodes.map(n => ({...n.frontmatter,id:n.id,html:n.html}));

	state.caseStudies = caseStudies;
	const article = _.find(caseStudies,{'slug': pathname});
	if(article) {
		state.articleView = true;
		state.article = article;
	}   
	this.setState(state);
  }

  componentDidMount() {
    if(document.body.classList.contains('noscroll')) {
        document.body.classList.remove('noscroll');
    }
  }

  render() {
	  
    return (this.state.articleView 
        ? <div><SingleCaseStudy article={this.state.article} section="case-studies" {...this.props }/></div>
        : 
        <PageTransition
            defaultStyle={{
                transition: 'all 500ms cubic-bezier(0.8, 0, 0.2, 1)',
                position: 'relative',
                width: '100%',
                opacity: 0,
                maxWidth: '2000px'
            }}
            transitionStyles={{
                entering: {
                    opacity: 1
                },
                entered: {
                    opacity: 1
                },
                exiting: {
                    opacity: 0,
                }
            }}
            transitionTime={500}
        >
            <Header
              headerStyle="print-header__alt"
              contactOpen={this.state.contactOpen}
              action={() => this.toggleContact()}
              {...this.props }
            />

            <Hero 
                pageTitleSub="featured"
                pageTitleMain="case_studies"
                featImage={heroImage}
            />

			<CaseStudiesListing caseStudies={this.state.caseStudies} />

            <CallToAction
              image={ctaImage}
              heading="tailor_made_perfect_fit"
              content="lets_get_in_touch"
              buttonLabel="contact_us"
              action={() => this.toggleContact()}
            />
            <Footer />

      </PageTransition>
      )
  }
}

export default withIntl(CaseStudies)

export const pageQuery = graphql`
{
	allMarkdownRemark {
	  nodes {
		id
		html
		frontmatter {
		  bottomline
		  date(formatString: "")
		  slug
		  title
		  topline
		}
	  }
	}
  }
`