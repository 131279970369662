import React from 'react'
import Scroller from '../global/icons/animated-mouse'
import PageTitle from '../global/titles/pagetitle'

const Hero = ({ pageTitleSub, pageTitleMain, featImage }) => (

  <section className="heroalt bg-white">

    {/* Page Title */}
    <div className="row expanded">
      <div className="small-12 medium-push-1 medium-6 columns">
        <div className="heroalt-title mpad">
          <div className="heroalt-title__content">
            <PageTitle
              subTitle={pageTitleSub}
              mainTitle={pageTitleMain}
              classes="light"
            />
          </div>
        </div>
      </div>
    </div>

    {/* Hero Image */}
    <div className="heroalt-image print-hero__image">
      <div className="heroalt-image__content">
        <img src={featImage} alt="Featured Hero Image" />
      </div>
    </div>
  </section>
)

export default Hero




