import React from 'react'
import { Link } from '../../i18n'

const CaseStudiesListing = ({ caseStudies }) => (
  <section className="storylist pad-top print-storylist">
      {(caseStudies ?? []).map(study => <div className="row expanded" key={study.id}>
          <div className="small-12 medium-push-1 medium-10 large-push-2 large-8 xlarge-push-3 xlarge-6 columns end print-listing">
              <main className="storylist-story">
                <em className="noline">{study.topline}</em>
                <Link to={`${study.slug}`} className="storylist-story__title">
                    <h3 className="dark">{study.title} solution</h3>
                </Link>
                <Link to={`${study.slug}`} className="dark button"><span></span>Read more</Link>
            </main>
          </div>
        </div>
      )}
  </section>
)

export default CaseStudiesListing

